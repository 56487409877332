exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-kosmetik-behandlungen-tsx": () => import("./../../../src/pages/kosmetik/behandlungen.tsx" /* webpackChunkName: "component---src-pages-kosmetik-behandlungen-tsx" */),
  "component---src-pages-kosmetik-chemischespeeling-tsx": () => import("./../../../src/pages/kosmetik/chemischespeeling.tsx" /* webpackChunkName: "component---src-pages-kosmetik-chemischespeeling-tsx" */),
  "component---src-pages-kosmetik-haarentfernung-tsx": () => import("./../../../src/pages/kosmetik/haarentfernung.tsx" /* webpackChunkName: "component---src-pages-kosmetik-haarentfernung-tsx" */),
  "component---src-pages-kosmetik-index-tsx": () => import("./../../../src/pages/kosmetik/index.tsx" /* webpackChunkName: "component---src-pages-kosmetik-index-tsx" */),
  "component---src-pages-kosmetik-makeup-tsx": () => import("./../../../src/pages/kosmetik/makeup.tsx" /* webpackChunkName: "component---src-pages-kosmetik-makeup-tsx" */),
  "component---src-pages-kosmetik-microdermabrasion-tsx": () => import("./../../../src/pages/kosmetik/microdermabrasion.tsx" /* webpackChunkName: "component---src-pages-kosmetik-microdermabrasion-tsx" */),
  "component---src-pages-kosmetik-microneedling-tsx": () => import("./../../../src/pages/kosmetik/microneedling.tsx" /* webpackChunkName: "component---src-pages-kosmetik-microneedling-tsx" */),
  "component---src-pages-kurse-index-tsx": () => import("./../../../src/pages/kurse/index.tsx" /* webpackChunkName: "component---src-pages-kurse-index-tsx" */),
  "component---src-pages-massagen-gewerblichemassagen-tsx": () => import("./../../../src/pages/massagen/gewerblichemassagen.tsx" /* webpackChunkName: "component---src-pages-massagen-gewerblichemassagen-tsx" */),
  "component---src-pages-massagen-heilmassagen-tsx": () => import("./../../../src/pages/massagen/heilmassagen.tsx" /* webpackChunkName: "component---src-pages-massagen-heilmassagen-tsx" */),
  "component---src-pages-massagen-index-tsx": () => import("./../../../src/pages/massagen/index.tsx" /* webpackChunkName: "component---src-pages-massagen-index-tsx" */),
  "component---src-pages-nails-index-tsx": () => import("./../../../src/pages/nails/index.tsx" /* webpackChunkName: "component---src-pages-nails-index-tsx" */),
  "component---src-pages-preislisten-heilmassagen-tsx": () => import("./../../../src/pages/preislisten/heilmassagen.tsx" /* webpackChunkName: "component---src-pages-preislisten-heilmassagen-tsx" */),
  "component---src-pages-preislisten-index-tsx": () => import("./../../../src/pages/preislisten/index.tsx" /* webpackChunkName: "component---src-pages-preislisten-index-tsx" */),
  "component---src-pages-preislisten-kosmetik-tsx": () => import("./../../../src/pages/preislisten/kosmetik.tsx" /* webpackChunkName: "component---src-pages-preislisten-kosmetik-tsx" */),
  "component---src-pages-preislisten-kursen-tsx": () => import("./../../../src/pages/preislisten/kursen.tsx" /* webpackChunkName: "component---src-pages-preislisten-kursen-tsx" */),
  "component---src-pages-preislisten-massagen-tsx": () => import("./../../../src/pages/preislisten/massagen.tsx" /* webpackChunkName: "component---src-pages-preislisten-massagen-tsx" */),
  "component---src-pages-preislisten-nails-tsx": () => import("./../../../src/pages/preislisten/nails.tsx" /* webpackChunkName: "component---src-pages-preislisten-nails-tsx" */)
}

